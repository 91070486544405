import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";
import { AddOutlined, DeleteOutlineOutlined } from "@mui/icons-material";

import { dateNormalizer, errorNormalizer } from "../../../../shared/Helpers/functions";
import { CustomButton, Picker, Text } from "../../../../shared/uiComponents";
import { useCompareDates } from "../../../../shared/Helpers/hooks";
import SearchSelect from "../../../../shared/uiComponents/Dropdown/searchSelect";
import { DataProperties } from "../../../../shared/uiComponents/Radio";
import { downloadFile } from "../BillingReports/downloadFile";
import "../reportStyles.scss";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { getUtilizationReport } from "../../../../redux/State/clientSlice/insuranceAuthorizationSlice";
import { getPatients } from "../../../../redux/State/clientSlice/clientSlice";

const startDate = dayjs().startOf("month");
const endDate = dayjs();

const UtilizationReport = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const [patientsData, setPatientsData] = useState<Array<DataProperties>>([]);
  const [clientIds, setClientIds] = useState<Array<string>>([]);
  const [clients, setClients] = useState<Array<DataProperties>>([]);
  const [clientId, setClientId] = useState<string>("");
  const [startDateValue, setStartDate] = useState<Dayjs>(startDate);
  const [endDateValue, setEndDate] = useState<Dayjs>(endDate);
  const [pageSize, setPageSize] = useState<number>(8);
  const [searchString, setSearchString] = useState<string>("");

  const loading = useSelector((state) => state.authorization.loading);
  const loadingExport = useSelector((state) => state.export.loading);
  const loadingPatients = useSelector((state) => state.client.loading);

  const { hasError, message } = useCompareDates(startDateValue, endDateValue);

  useEffect(() => {
    if (!pageSize) return;

    const timeout = setTimeout(() => {
      dispatch(
        getPatients({ page: "1", pageSize: `${pageSize}`, searchString })
      ).then(unwrapResult).then(response => {
        if (!response.query) return;
        const data: Array<DataProperties> = response.query
          .filter(x => !clientIds.includes(x.id))
          .map((patient) => ({
            id: patient.id,
            label: patient.fullName,
          }));
        setPatientsData(data);
      });
    }, 1000);
    return () => clearTimeout(timeout);
  }, [dispatch, pageSize, searchString, clientIds]);

  const downloadHandler = useCallback(() => {
    const startDate = dateNormalizer(startDateValue);
    const endDate = dateNormalizer(endDateValue);

    if (!clientIds.length) return;
    dispatch(
      getUtilizationReport({
        clientIds,
        startDate,
        endDate,
      })
    )
      .then(unwrapResult)
      .then((response) => downloadFile(response))
      .catch(errorNormalizer);
  }, [dispatch, clientIds, endDateValue, startDateValue]);


  const onChangeStartDate = (date: Dayjs | null) => {
    if (!date) return;
    setStartDate(date);
  };

  const onChangeEndDate = (date: Dayjs | null) => {
    if (!date) return;
    setEndDate(date);
  };

  const addHandler = () => {
    if (!clientId) return;
    setClientIds(prev => [...prev, clientId]);
    const client = patientsData.find(x => x.id === clientId);
    setClientId("")
    setSearchString("");
    if (!client) return;
    setClients(prev => [...prev, client]);
  }

  const onClientSelectChange = (id: string) => {
    if (!id) return;
    setClientId(id)
  }

  const onClientRemove = (id: string) => {
    if (!id) return;
    setClientIds(prev => prev.filter(x => x !== id));
    setClients(prev => prev.filter(x => x.id !== id));
  }

  return (
    <div>
      {
        !!clients &&
        !!clients.length &&
        <div
          className="marginBottom8"
          style={{
            width: 'max-content',
            border: '1px solid black',
            borderRadius: '12px',
            padding: '8px',
          }}>
          <Text title={"Selected Clients:"} size="smallBold" className="marginBottom8" />
          {
            clients.map((x, index) => (<div style={{ display: 'flex', gap: '16px' }}>
              <Text title={`${index + 1}. ${x.label}`} />
              <DeleteOutlineOutlined onClick={() => onClientRemove(x.id)} fontSize="small" />
            </div>))
          }
        </div>
      }
      <div style={{ width: "250px", display: 'flex', gap: '16px' }}>
        <SearchSelect
          label="Select Clients"
          searchString={searchString}
          setSearchString={setSearchString}
          data={patientsData}
          setValue={onClientSelectChange}
          loadMore={{
            activate: true,
            setSize: setPageSize,
          }}
          loading={loadingPatients || loadingExport}
        />
        <AddOutlined fontSize="large" onClick={addHandler} className="marginTop24" />
      </div>
      <div style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
        <Picker.CustomDate
          label="Start Date"
          value={startDateValue}
          onChange={onChangeStartDate}
          disabled={loadingExport}
        />
        <Picker.CustomDate
          label="End Date"
          error={hasError}
          errorMessage={message}
          value={endDateValue}
          onChange={onChangeEndDate}
          disabled={loadingExport}
        />
      </div>
      <div style={{ width: 'max-content' }}>
        <CustomButton
          title="Download"
          onClick={downloadHandler}
          loading={loading}
          disabled={!clientIds.length}
        />
      </div>
    </div>
  );
};

export default UtilizationReport;
