import { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";

import { useCompareDates } from "../../../shared/Helpers/hooks";
import {
  CustomButton,
  CustomCheckBox,
  Picker,
  Text,
} from "../../../shared/uiComponents";
import SearchSelect from "../../../shared/uiComponents/Dropdown/searchSelect";
import { DataProperties } from "../../../shared/uiComponents/Radio";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { getUsers } from "../../../redux/State/userSlice";
import {
  getTherapists,
  getUserPatients,
} from "../../../redux/State/clientSlice/userClientSlice";
import { getPatients } from "../../../redux/State/clientSlice/clientSlice";
import { TimeInputsWrapper } from "../../BCBAPanel/Calendar/Popups/addEvent";
import { useCheckPermission } from "../Permissions/helpers";
import { PERMISSIONS } from "../../../App/constants";

const today = new Date();
const startDate = dayjs().startOf("month");
const endDate = dayjs(today);

export const BillingFilters = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params, setParams] = useSearchParams();
  const CLIENT = useCheckPermission(PERMISSIONS.CLIENT.READ);
  const USER = useCheckPermission(PERMISSIONS.USER.READ);

  const clientId = params.get("clientId");
  const userId = params.get("userId");

  const users = useSelector((state) => state.user.users);
  const clients = useSelector((state) => state.client.patients);
  const userClients = useSelector((state) => state.userClient.userPatients);
  const clientUsers = useSelector((state) => state.userClient.therapists);
  const loadingUsers = useSelector((state) => state.user.loading);
  const loadingPatients = useSelector((state) => state.client.loading);
  const loadingBilling = useSelector((state) => state.billing.loading);

  const [startDateValue, setStartDate] = useState<Dayjs>(startDate);
  const [endDateValue, setEndDate] = useState<Dayjs>(endDate);
  const { hasError, message } = useCompareDates(startDateValue, endDateValue);

  const [searchUser, setSearchUser] = useState<string>("");
  const [searchClient, setSearchClient] = useState<string>("");
  const [pageSizeUser, setPageSizeUser] = useState<number>(8);
  const [pageSizeClient, setPageSizeClient] = useState<number>(8);
  const [usersData, setUsersData] = useState<Array<DataProperties>>([]);
  const [clientsData, setClientsData] = useState<Array<DataProperties>>([]);

  useEffect(() => {
    if (!!params.get("startDate")) return;
    params.set("startDate", startDateValue.format("MM-DD-YYYY"));
    setParams(params);
  }, [params, startDateValue, setParams]);

  useEffect(() => {
    if (typeof params.get("onlyNew") === "string") return;
    params.set("onlyNew", "true");
    setParams(params);
  }, [params, setParams]);

  useEffect(() => {
    if (!!params.get("endDate")) return;
    params.set("endDate", endDateValue.format("MM-DD-YYYY"));
    setParams(params);
  }, [endDateValue, setParams, params]);

  useEffect(() => {
    if (!pageSizeUser || !!clientId || !USER.permissionGranted) return;
    const timeout = setTimeout(() => {
      dispatch(
        getUsers({
          page: "1",
          pageSize: `${pageSizeUser}`,
          searchString: searchUser,
        })
      );
    }, 1000);
    return () => clearTimeout(timeout);
  }, [dispatch, pageSizeUser, searchUser, clientId, USER.permissionGranted]);

  useEffect(() => {
    if (!clientId || !USER.permissionGranted) return;

    const timeout = setTimeout(() => {
      dispatch(getTherapists(clientId));
    }, 1000);
    return () => clearTimeout(timeout);
  }, [dispatch, clientId, USER.permissionGranted]);

  useEffect(() => {
    if (!pageSizeClient || !CLIENT.permissionGranted) return;

    if (!userId) {
      const timeout = setTimeout(() => {
        dispatch(
          getPatients({
            page: "1",
            pageSize: `${pageSizeClient}`,
            searchString: searchClient,
          })
        );
      }, 1000);
      return () => clearTimeout(timeout);
    }

    const timeout = setTimeout(() => {
      dispatch(
        getUserPatients({
          userId,
          page: "1",
          pageSize: `${pageSizeClient}`,
          searchString: searchClient,
        })
      );
    }, 1000);
    return () => clearTimeout(timeout);
  }, [
    dispatch,
    pageSizeClient,
    searchClient,
    userId,
    CLIENT.permissionGranted,
  ]);

  useEffect(() => {
    if (!users || !users.query || !!clientId) return;
    const data: Array<DataProperties> = users.query.map((user) => ({
      id: user.id,
      label: user.fullName,
    }));
    setUsersData(data);
  }, [users, clientId]);

  useEffect(() => {
    if (!clientUsers || !clientId) return;
    const data: Array<DataProperties> = clientUsers.map((user) => ({
      id: user.id,
      label: user.fullName,
    }));
    setUsersData(data);
  }, [clientUsers, clientId]);

  useEffect(() => {
    if (!userClients || !userClients.query || !userId) return;
    const data: Array<DataProperties> = userClients.query.map((client) => ({
      id: client.id,
      label: client.fullName,
    }));
    setClientsData(data);
  }, [userClients, userId]);

  useEffect(() => {
    if (!clients || !clients.query || !!userId) return;
    const data: Array<DataProperties> = clients.query.map((client) => ({
      id: client.id,
      label: client.fullName,
    }));
    setClientsData(data);
  }, [clients, userId]);

  const hadleOnChangeStartDate = (date: Dayjs | null) => {
    if (!date) return;
    setStartDate(date);
    params.set("startDate", date.format("MM-DD-YYYY"));
    setParams(params);
  };

  const hadleOnChangeEndDate = (date: Dayjs | null) => {
    if (!date) return;
    setEndDate(date);
    params.set("endDate", date.format("MM-DD-YYYY"));
    setParams(params);
  };

  const onShowNewChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (!checked) {
      params.set("onlyNew", "");
      setParams(params);
      return;
    }
    params.set("onlyNew", "true");
    setParams(params);
  };

  const userHandler = (value: string) => {
    params.set("userId", value);
    setParams(params);
  };

  const clientHandler = (value: string) => {
    params.set("clientId", value);
    setParams(params);
  };

  const thisMonthHandler = () => {
    const startDayOfMonth = dayjs(today).subtract(today.getDate() - 1, "days");
    const currentDate = dayjs(today);
    params.set("startDate", startDayOfMonth.format("MM.DD.YYYY"));
    params.set("endDate", currentDate.format("MM.DD.YYYY"));
    params.set("onlyNew", "true");
    setStartDate(startDayOfMonth);
    setEndDate(currentDate);
    setParams(params);
  };

  const lastMonthHandler = () => {
    const beginningOfLastMoth = dayjs(today)
      .subtract(today.getDate() - 1, "days")
      .subtract(1, "month");
    const endOfLastMoth = dayjs(today).subtract(today.getDate(), "days");
    params.set("startDate", beginningOfLastMoth.format("MM.DD.YYYY"));
    params.set("endDate", endOfLastMoth.format("MM.DD.YYYY"));
    params.set("onlyNew", "true");
    setStartDate(beginningOfLastMoth);
    setEndDate(endOfLastMoth);
    setParams(params);
  };

  const thisYearHandler = () => {
    const startOfYear = dayjs(today)
      .subtract(today.getDate() - 1, "days")
      .subtract(today.getMonth(), "month");
    const currentDate = dayjs(today);
    params.set("startDate", startOfYear.format("MM.DD.YYYY"));
    params.set("endDate", currentDate.format("MM.DD.YYYY"));
    params.set("onlyNew", "true");
    setStartDate(startOfYear);
    setEndDate(currentDate);
    setParams(params);
  };

  return (
    <>
      <TimeInputsWrapper style={{ justifyContent: "flex-start", gap: "16px" }}>
        {CLIENT.permissionGranted && (
          <div style={{ width: "250px" }}>
            <SearchSelect
              label="Find Client:"
              searchString={searchClient}
              setSearchString={setSearchClient}
              data={clientsData}
              setValue={clientHandler}
              loadMore={{
                activate: true,
                setSize: setPageSizeClient,
              }}
              loading={loadingPatients || loadingBilling}
            />
          </div>
        )}
        {USER.permissionGranted && (
          <div style={{ width: "250px" }}>
            <SearchSelect
              label="Find Provider:"
              searchString={searchUser}
              setSearchString={setSearchUser}
              data={usersData}
              setValue={userHandler}
              loadMore={{
                activate: true,
                setSize: setPageSizeUser,
              }}
              loading={loadingUsers || loadingBilling}
            />
          </div>
        )}
      </TimeInputsWrapper>
      <TimeInputsWrapper style={{ justifyContent: "flex-start", gap: "16px" }}>
        <Picker.CustomDate
          label="Start Date"
          value={startDateValue}
          onChange={hadleOnChangeStartDate}
          disabled={loadingBilling}
        />
        <Picker.CustomDate
          label="End Date"
          error={hasError}
          errorMessage={message}
          value={endDateValue}
          onChange={hadleOnChangeEndDate}
          disabled={loadingBilling}
        />
      </TimeInputsWrapper>
      <CustomCheckBox
        item={{
          label: "Show only new",
          id: "0",
          checked: !!params.get("onlyNew"),
          disabled: loadingBilling,
        }}
        onChange={onShowNewChange}
      />
      <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
        <Text title={"Set parameters to: "} size="mediumBold" />
        <CustomButton
          title="This month"
          onClick={thisMonthHandler}
          secondaryButton
          disabled={loadingBilling}
        />
        <CustomButton
          title="Last month"
          onClick={lastMonthHandler}
          secondaryButton
          disabled={loadingBilling}
        />
        <CustomButton
          title="This year"
          onClick={thisYearHandler}
          secondaryButton
          disabled={loadingBilling}
        />
      </div>
    </>
  );
};

export const Filters = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params, setParams] = useSearchParams();

  const [patientsData, setPatientsData] = useState<Array<DataProperties>>([]);
  const [startDateValue, setStartDate] = useState<Dayjs>(startDate);
  const [endDateValue, setEndDate] = useState<Dayjs>(endDate);
  const [pageSize, setPageSize] = useState<number>(8);
  const [searchString, setSearchString] = useState<string>("");

  const patients = useSelector((state) => state.client.patients);
  const loadingPatients = useSelector((state) => state.client.loading);
  const loadingExport = useSelector((state) => state.export.loading);
  const { hasError, message } = useCompareDates(startDateValue, endDateValue);

  const onSelectChange = (value: string) => {
    params.set("clientId", value);
    setParams(params);
  };

  const onChangeStartDate = (date: Dayjs | null) => {
    if (!date) return;
    setStartDate(date);
  };

  const onChangeEndDate = (date: Dayjs | null) => {
    if (!date) return;
    setEndDate(date);
  };

  useEffect(() => {
    params.set("startDate", startDateValue.format("MM.DD.YYYY"));
    setParams(params);
  }, [startDateValue, params, setParams]);

  useEffect(() => {
    params.set("endDate", endDateValue.format("MM.DD.YYYY"));
    setParams(params);
  }, [endDateValue, params, setParams]);

  useEffect(() => {
    if (!pageSize) return;

    const timeout = setTimeout(() => {
      dispatch(
        getPatients({ page: "1", pageSize: `${pageSize}`, searchString })
      );
    }, 1000);
    return () => clearTimeout(timeout);
  }, [dispatch, pageSize, searchString]);

  useEffect(() => {
    if (!patients || !patients.query) return;
    const data: Array<DataProperties> = patients.query.map((patient) => ({
      id: patient.id,
      label: patient.fullName,
    }));
    setPatientsData(data);
  }, [patients]);

  return (
    <>
      <div style={{ width: "250px" }}>
        <SearchSelect
          label="Select Patient"
          searchString={searchString}
          setSearchString={setSearchString}
          data={patientsData}
          setValue={onSelectChange}
          loadMore={{
            activate: true,
            setSize: setPageSize,
          }}
          loading={loadingPatients || loadingExport}
        />
      </div>
      <div>
        <Picker.CustomDate
          label="Start Date"
          value={startDateValue}
          onChange={onChangeStartDate}
          disabled={loadingExport}
        />
        <Picker.CustomDate
          label="End Date"
          error={hasError}
          errorMessage={message}
          value={endDateValue}
          onChange={onChangeEndDate}
          disabled={loadingExport}
        />
      </div>
    </>
  );
};
