import { FC, useState, useEffect, useCallback } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";

import {
  Text,
  CustomAccordion,
  TableNoData,
} from "../../../shared/uiComponents";
import { Loader, InfoRow as Row } from "../StyledComponents";
import Action from "../../../components/Action";
import Delete from "../../../components/Delete";

import {
  HeaderProperties,
  TableDataProperties,
} from "../../../shared/uiComponents/Table/tableProperties";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { UserProperties } from "../../../redux/API/userAPIProperties";
import { getUsers } from "../../../redux/State/userSlice";
import {
  dismissPatient,
  getUserPatients,
} from "../../../redux/State/clientSlice/userClientSlice";
import { DismissClientProperties } from "../../../redux/API/ClientAPIHelpers/userClientProperties";
import { UserStatusBadge } from "../../../shared/uiComponents/StatusBadge";
import { errorNormalizer } from "../../../shared/Helpers/functions";

export interface AdminDataProperties extends TableDataProperties {
  query: Array<UserProperties> | null;
}

interface RowRendererProperties {
  data: UserProperties[];
}

export const Headers: HeaderProperties[] = [
  { id: "0", name: "Full Name", orderBy: "fullName" },
  { id: "1", name: "Email", orderBy: "email" },
  { id: "3", name: "Status", orderBy: "emailConfirmed" },
  { id: "4", name: "Licensed or Limited", orderBy: "authorizationType" },
  {
    id: "5",
    name: "Role",
    titles: ["Admin", "BCBA", "BT", "All"], //All keyword is for reseting column
    titlesHeader: "Select Section:",
  },
  { id: "6", name: "Actions", width: "120px" },
];

export const UserInfo = (info: UserProperties) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const [patientsPageSize, setPatientsPageSize] = useState<number>(8);

  const patients = useSelector((state) => state.userClient.userPatients);
  const loading = useSelector(
    (state) => state.userClient.loadingGetUserPatients
  );

  const fetchUsers = () => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";

    dispatch(getUsers({ page, pageSize, orderBy, direction, searchString }));
  };

  const fetchUserPatients = useCallback(() => {
    const page = "1";
    const userId = info.id;
    if (!userId) return;
    dispatch(
      getUserPatients({ userId, page, pageSize: patientsPageSize.toString() })
    );
  }, [info, patientsPageSize, dispatch]);

  const dismissHandler = ({ clientId, userId }: DismissClientProperties) => {
    dispatch(dismissPatient({ clientId, userId }))
      .then(unwrapResult)
      .then(() => {
        toast("Removed");
        fetchUserPatients();
        fetchUsers();
      })
      .catch(errorNormalizer);
  };

  useEffect(() => {
    fetchUserPatients();
  }, [fetchUserPatients]);

  return (
    <>
      <Row>
        <Text title={"First Name: "} size={"smallBold"} />
        <Text title={info.firstName} />
      </Row>
      <Row>
        <Text title={"Last Name: "} size={"smallBold"} />
        <Text title={info.lastName} />
      </Row>
      <Row>
        <Text title={"Email: "} size={"smallBold"} />
        <Text title={info.email} />
      </Row>
      <Row>
        <Text title={"Status: "} size={"smallBold"} />
        <div className="paddingLeft16">
          <UserStatusBadge emailConfirmed={info.emailConfirmed} />
        </div>
      </Row>
      <Row>
        <Text title={"Address: "} size={"smallBold"} />
        <Text title={info.address} />
      </Row>
      {info.authorizationType?.id === 1 && (
        <>
          <Row>
            <Text title={"Authorization Type: "} size={"smallBold"} />
            <Text title={info.authorizationType?.name ?? "-"} />
          </Row>
          <Row>
            <Text title={"Role: "} size={"smallBold"} />
            <Text title={info.role.name} />
          </Row>
          <Row>
            <Text title={"Section: "} size={"smallBold"} />
            <Text title={info.role.section.name} />
          </Row>
        </>
      )}
      <Row>
        <Text title={"Created by: "} size={"smallBold"} />
        <Text title={info.createdBy.fullName} />
      </Row>
      <CustomAccordion
        headerStyle={{ padding: 0 }}
        HeaderContent={() => (
          <Row>
            <Text title={"Clients Assigned: "} size={"smallBold"} />
            <Text title={`${info.numberOfClients}`} />
          </Row>
        )}
        Content={() => (
          <>
            {!!patients.query?.length && !loading ? (
              <>
                {patients.query?.map((patient, index) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    key={index}
                  >
                    <Text
                      title={`${index + 1}. ${patient.fullName}`}
                      className={"marginRight8"}
                    />
                    {!!patient.id && !!info.id && (
                      <Delete
                        deleteHandler={() =>
                          dismissHandler({
                            clientId: patient.id,
                            userId: info.id,
                          })
                        }
                      />
                    )}
                  </div>
                ))}
                {patients.hasNextPage && (
                  <div style={{ justifyContent: "center", display: "flex" }}>
                    <Text
                      title="Load More"
                      size="smallBold"
                      onClick={() => setPatientsPageSize((prev) => prev + 8)}
                    />
                  </div>
                )}
              </>
            ) : (
              <Text title={"None"} />
            )}
            {loading && <Loader />}
          </>
        )}
      />
    </>
  );
};

export const initialUserTableState: UserProperties = {
  id: "",
  firstName: "",
  lastName: "",
  fullName: "",
  patronymic: "",
  numberOfClients: 0,
  phoneNumber: "",
  address: "",
  notes: "",
  email: "",
  numberOfNotes: 0,
  role: {
    id: "",
    name: "",
    section: {
      id: 0,
      name: "",
    },
  },
  createdBy: {
    id: "",
    fullName: "",
    role: "",
  },
  authorizationType: {
    id: 0,
    name: "",
  },
  emailConfirmed: false,
};

export const TableBody: FC<RowRendererProperties> = ({ data }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const fetchUsers = () => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";
    const section = params.get("status");

    dispatch(
      getUsers({ page, pageSize, orderBy, direction, searchString, section })
    );
  };

  if (!data || !data.length) {
    return <TableNoData spanScope={Headers.length} />;
  }

  return (
    <Body>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>
            <Text title={row.fullName} size={"tiny"} />
          </TableCell>
          <TableCell>
            <Text title={row.email} size={"tiny"} />
          </TableCell>
          <TableCell>
            <UserStatusBadge emailConfirmed={row.emailConfirmed} />
          </TableCell>
          <TableCell>
            <Text
              title={!!row.authorizationType ? row.authorizationType.name : "-"}
              size={"tiny"}
            />
          </TableCell>
          <TableCell>
            <Text title={row.role.name} size={"tiny"} />
          </TableCell>
          <TableCell>
            <Action item={row} fetchUsers={fetchUsers} />
          </TableCell>
        </TableRow>
      ))}
    </Body>
  );
};
