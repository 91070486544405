import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import SearchSelect, {
  SelectDataProperties,
} from "../../../shared/uiComponents/Dropdown/searchSelect";
import { Picker, Text } from "../../../shared/uiComponents";
import { dateNormalizer } from "../../../shared/Helpers/functions";
import { red300 } from "../../../shared/Helpers/colors";
import { useCheckPermission } from "../../AdminPanel/Permissions/helpers";
import { PERMISSIONS } from "../../../App/constants";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  getUserPatients,
  getUserRBTs,
} from "../../../redux/State/clientSlice/userClientSlice";

const Filters = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const CLIENT = useCheckPermission(PERMISSIONS.CLIENT.READ);

  const todayDayjs = dayjs();
  const pastDateMonth = dayjs().startOf("month");

  const [params, setParams] = useSearchParams();

  const clientId = params.get("clientId");
  const rbtId = params.get("userId");

  const userId = useSelector((state) => state.account.user.id);
  const loadingPatients = useSelector((state) => state.client.loading);
  const rbts = useSelector((state) => state.userClient.userRBTs);

  const [startDateValue, setStartDateValue] = useState<Dayjs>(pastDateMonth);
  const [endDateValue, setEndDateValue] = useState<Dayjs>(todayDayjs);

  const [searchStringTherapists, setSearchStringTherapists] =
    useState<string>("");
  const [searchStringPatients, setSearchStringPatients] = useState<string>("");
  const [rbtList, setRbtList] = useState<Array<SelectDataProperties>>([]);
  const [pageSize, setPageSize] = useState<number>(8);
  const [patientsData, setPatientsData] = useState<Array<SelectDataProperties>>(
    []
  );

  const choosenProvider = rbtList.find((x) => x.id === rbtId);
  const choosenClient = patientsData.find((x) => x.id === clientId);

  const onChangeStartDate = (value: Dayjs | null) => {
    if (!value) return;
    setStartDateValue(value);
    const start = dateNormalizer(value);
    params.set("startDate", start);
    setParams(params);
  };

  const onChangeEndDate = (value: Dayjs | null) => {
    if (!value) return;
    setEndDateValue(value);
    const end = dateNormalizer(value);
    params.set("endDate", end);
    setParams(params);
  };

  const onTherapistChoose = (value: string) => {
    params.set("userId", value);
    params.delete("clientId");
    setParams(params);
  };

  const onPatientChoose = (value: string) => {
    params.set("clientId", value);
    setParams(params);
  };

  useEffect(() => {
    if (!!clientId) return;
    setSearchStringPatients("");
  }, [clientId]);

  useEffect(() => {
    const startDate = params.get("startDate");
    const endDate = params.get("endDate");
    if (!!startDate || !!endDate) return;
    const start = dateNormalizer(startDateValue);
    const end = dateNormalizer(endDateValue);

    params.set("startDate", start);
    params.set("endDate", end);
    setParams(params);
  }, [params, setParams, startDateValue, endDateValue]);

  useEffect(() => {
    if (!rbts || !rbts.length) return;
    if (!searchStringTherapists.length) {
      const list = rbts.map((x) => ({ id: x.id, label: x.fullName }));
      setRbtList(list);
      return;
    }
    const list = rbts
      .filter((x) =>
        x.fullName.toLowerCase().includes(searchStringTherapists.toLowerCase())
      )
      .map((x) => ({ id: x.id, label: x.fullName }));
    setRbtList(list);
  }, [rbts, searchStringTherapists]);

  useEffect(() => {
    if (!pageSize || !userId || !CLIENT.permissionGranted) return;

    const timeout = setTimeout(() => {
      dispatch(
        getUserPatients({
          userId: !!rbtId ? rbtId : userId,
          page: "1",
          pageSize: `${pageSize}`,
          searchString: searchStringPatients,
        })
      )
        .then(unwrapResult)
        .then((response) => {
          if (!response.query) return;
          const data = response.query.map((patient) => ({
            id: patient.id,
            label: patient.fullName,
          }));
          setPatientsData(data);
        });
    }, 1000);
    return () => clearTimeout(timeout);
  }, [
    dispatch,
    pageSize,
    rbtId,
    userId,
    searchStringPatients,
    CLIENT.permissionGranted,
  ]);

  useEffect(() => {
    if (!userId) return;
    dispatch(getUserRBTs(userId));
  }, [userId, dispatch]);

  return (
    <>
      <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
        <div>
          <SearchSelect
            data={rbtList}
            className={"marginRight16"}
            label="Find Provider:"
            setSearchString={setSearchStringTherapists}
            searchString={searchStringTherapists}
            loading={false}
            setValue={onTherapistChoose}
          />
        </div>
        {CLIENT.permissionGranted && (
          <div>
            <SearchSelect
              label={`Find ${!!choosenProvider ? `${choosenProvider.label}'s` : ""
                } Client:`}
              data={patientsData}
              className={"marginRight16"}
              setSearchString={setSearchStringPatients}
              searchString={searchStringPatients}
              setValue={onPatientChoose}
              loading={loadingPatients}
              loadMore={{
                activate: true,
                setSize: setPageSize,
              }}
            />
          </div>
        )}
        <Picker.CustomDate
          label="Start Date:"
          value={startDateValue}
          onChange={onChangeStartDate}
        />
        <Picker.CustomDate
          label="End Date:"
          value={endDateValue}
          onChange={onChangeEndDate}
        />
      </div>
      <Text
        textColor={red300}
        size="smallBold"
        title={`Displaying ${!!clientId
          ? `${choosenClient?.label}'s`
          : !!rbtId
            ? `${choosenProvider?.label}'s`
            : "all"
          } notes`}
      />
    </>
  );
};

export default Filters;
