import { useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import { dateNormalizer } from "../../../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../../../redux/store";
import { getHourPerWeekInsuranceAuthorizationsData } from "../../../../../redux/State/clientSlice/insuranceAuthorizationSlice";

export const useCalculateHoursPerWeek = ({
  endDateValue,
  startDateValue,
  totalHours,
  frequency,
}: {
  startDateValue: Dayjs;
  endDateValue: Dayjs;
  totalHours: string;
  frequency: number;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const [hoursPerWeek, setHoursPerWeek] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!startDateValue || !endDateValue || frequency !== 1) return;
    setLoading(true);
    const startDate = dateNormalizer(startDateValue);
    const endDate = dateNormalizer(endDateValue);
    dispatch(
      getHourPerWeekInsuranceAuthorizationsData({
        startDate,
        endDate,
        totalHours: parseFloat(totalHours),
      })
    )
      .then(unwrapResult)
      .then((response) => {
        const { hoursPerWeek } = response;
        setHoursPerWeek(hoursPerWeek);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [startDateValue, endDateValue, totalHours, frequency, dispatch]);

  return { hoursPerWeek, loading };
};
