import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { InfoOutlined } from "@mui/icons-material";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";

import { Container } from "../../pages/AdminPanel/SOAPNotes/helpers";
import { InfoRow, Loader } from "../../pages/AdminPanel/StyledComponents";
import Text from "../../shared/uiComponents/Text";
import {
  CustomAlert,
  CustomTable,
  TableNoData,
} from "../../shared/uiComponents";

import { DispatchProperties, useSelector } from "../../redux/store";
import { getAvailableHoursForDashboard } from "../../redux/State/clientSlice/userClientSlice";
import {
  AvailableHoursForDashboardProperties,
  AvailableHoursProperties,
} from "../../redux/API/ClientAPIHelpers/userClientProperties";

const Dashboard = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const user = useSelector((state) => state.account.user);
  const role = useSelector((state) => state.account.role);
  const loadingAccount = useSelector((state) => state.account.loading);
  const hoursInfo = useSelector((state) => state.userClient.availableHours);
  const loading = useSelector((state) => state.userClient.loading);

  useEffect(() => {
    if (!user.id || !role) return;
    const userId = user.id;
    const type = role.section.id;
    dispatch(getAvailableHoursForDashboard({ userId, type }));
  }, [user, role, dispatch]);

  return (
    <Container style={{ maxWidth: "350px" }}>
      <Text title={"Account Information"} size="mediumBold" />
      {!!user && !!user.id && !loadingAccount && (
        <>
          <InfoRow>
            <Text title={"Name:"} size="smallBold" />
            <Text title={`${user.firstName} ${user.lastName}`} />
          </InfoRow>
          <InfoRow>
            <Text title={"Email:"} size="smallBold" />
            <Text title={user.email} />
          </InfoRow>
        </>
      )}
      {!!role && !!role.id && !loadingAccount && (
        <>
          <InfoRow>
            <Text title={"Role:"} size="smallBold" />
            <Text title={role.name} />
          </InfoRow>
          <InfoRow>
            <Text title={"Section:"} size="smallBold" />
            <Text title={role.section.name} />
          </InfoRow>
        </>
      )}
      {!!hoursInfo && (
        <CustomTable
          headers={[
            { id: "1", name: "Name" },
            { id: "2", name: "Authorization" },
          ]}
          data={{ query: hoursInfo }}
          TableBody={TableBody}
          loading={loading}
          hasPagination={false}
        />
      )}
      {loadingAccount && <Loader />}
    </Container>
  );
};

interface RowRendererProperties {
  data: Array<AvailableHoursForDashboardProperties>;
}

const TableBody: FC<RowRendererProperties> = ({ data }) => {
  const [availableHours, setAvailableHours] =
    useState<AvailableHoursProperties | null>(null);
  if (!data || !data.length) {
    return <TableNoData spanScope={Headers.length} />;
  }

  return (
    <Body>
      <TableRow>
        <TableCell />
        <TableCell>
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <Text title={"Start Date"} size="tinyBold" className="width150" />
            <Text title={"End Date"} size="tinyBold" className="width150" />
          </div>
        </TableCell>
      </TableRow>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>
            <Text
              title={row.client.fullName as string}
              className="paddingLeft16"
            />
          </TableCell>
          <TableCell>
            {!!row.availableHours &&
              [...row.availableHours]
                .sort((a, b) => {
                  const c = new Date(a.startDate).getDate();
                  const d = new Date(b.endDate).getDate();
                  if (c > d) return 1;
                  if (c < d) return -1;
                  return 0;
                })
                .map((item) => (
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      paddingBottom: "8px",
                    }}
                  >
                    <Text
                      title={dayjs(item.startDate).format("MM-DD-YYYY")}
                      className="width150"
                    />
                    <Text
                      title={dayjs(item.endDate).format("MM-DD-YYYY")}
                      className="width150"
                    />
                    <InfoOutlined
                      color={
                        dayjs().isAfter(item.endDate) ? "error" : "success"
                      }
                      onClick={() => setAvailableHours(item)}
                    />
                  </div>
                ))}
          </TableCell>
        </TableRow>
      ))}
      <CustomAlert
        open={!!availableHours}
        onClose={() => setAvailableHours(null)}
        Content={() => (
          <div>
            {!!availableHours && (
              <>
                <InfoRow>
                  <Text title={"Session type:"} />
                  <Text title={availableHours.sessionType.name} />
                </InfoRow>
                <InfoRow>
                  <Text title={"Frequency type:"} />
                  <Text title={availableHours.frequency.name} />
                </InfoRow>
                {availableHours.frequency.id === 1 &&
                  !!availableHours.hoursPerWeek && (
                    <InfoRow>
                      <Text title={"Remaining:"} />
                      <Text
                        title={`${availableHours.remainingHoursPerWeek}/${availableHours.hoursPerWeek}`}
                      />
                    </InfoRow>
                  )}
                {!!availableHours.totalHours && (
                  <InfoRow>
                    <Text title={"Total:"} />
                    <Text
                      title={`${availableHours.remainingTotalHours}/${availableHours.totalHours}`}
                    />
                  </InfoRow>
                )}
                <InfoRow>
                  <Text title={"Start date:"} />
                  <Text
                    title={dayjs(availableHours.startDate).format("MM-DD-YYYY")}
                  />
                </InfoRow>
                <InfoRow>
                  <Text title={"End date:"} />
                  <Text
                    title={dayjs(availableHours.endDate).format("MM-DD-YYYY")}
                  />
                </InfoRow>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {dayjs().isAfter(availableHours.endDate) ? (
                    <Text title={"Expired"} size="smallBold" />
                  ) : (
                    <Text
                      title={`Expires in ${dayjs(availableHours.endDate).diff(
                        dayjs(),
                        "days"
                      )} day${
                        dayjs(availableHours.endDate).diff(dayjs(), "days") > 1
                          ? "s"
                          : ""
                      }`}
                      size="smallBold"
                    />
                  )}
                </div>
              </>
            )}
          </div>
        )}
      />
    </Body>
  );
};

export default Dashboard;
