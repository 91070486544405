import { ChangeEvent, useEffect, useState, FC, Dispatch } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material/Select";
import dayjs, { Dayjs } from "dayjs";
import { Value } from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";

import {
  Text,
  CustomInput,
  CustomButton,
  CustomSelect,
  Picker,
} from "../../../../shared/uiComponents";
import {
  AdminContainer as Container,
  InputWrapper,
  Loader,
} from "../../StyledComponents";
import {
  capitalizer,
  dateNormalizer,
  validateEmail,
  validateName,
} from "../../../../shared/Helpers/functions";
import { CustomPhoneInput } from "../../../../shared/uiComponents/Input";
import { ACTIONS, ActionProperties } from "./patientReducer";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import {
  getDiagnosisCodes,
  getInsurances,
  getStatuses,
} from "../../../../redux/State/clientSlice/clientSlice";
import {
  AddClientProperties,
  UpdateClientInfoProperties,
} from "../../../../redux/API/ClientAPIHelpers/clientProperties";
import CustomRadio from "../../../../shared/uiComponents/Radio";

const genderOptions = [
  { id: "male", name: "Male" },
  { id: "female", name: "Female" },
];

interface PatientFormProperties {
  patientInfo: AddClientProperties | UpdateClientInfoProperties;
  setPatientInfo: Dispatch<ActionProperties>;
  savePatientHandler: () => void;
}

interface ErrorProperties {
  firstName: string;
  lastName: string;
  email: string;
  parentPhoneNumber: string;
  dateOfBirthday: string;
  gender: string;
  insurance: string;
  insuranceNumber: string;
}

const PatientForm: FC<PatientFormProperties> = ({
  patientInfo,
  setPatientInfo,
  savePatientHandler,
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { patientId } = useParams();

  const [dateValue, setDateValue] = useState<Dayjs | null>(null);
  const [error, setError] = useState<ErrorProperties>({
    firstName: "",
    lastName: "",
    email: "",
    parentPhoneNumber: "",
    dateOfBirthday: "",
    gender: "",
    insurance: "",
    insuranceNumber: "",
  });
  const [disableSave, setDisableSave] = useState<boolean>(true);

  const loading = useSelector((state) => state.client.loading);
  const insurances = useSelector((state) => state.client.insurances);
  const statuses = useSelector((state) => state.client.statuses);
  const diagnosisCodes = useSelector((state) => state.client.diagnosisCodes);

  useEffect(() => {
    dispatch(getInsurances());
    dispatch(getStatuses());
    dispatch(getDiagnosisCodes());
  }, [dispatch]);

  useEffect(() => {
    if (!!patientInfo.email && !validateEmail(patientInfo.email)) {
      setError((prev) => ({ ...prev, email: "Invalid email" }));
    } else {
      setError((prev) => ({ ...prev, email: "" }));
    }
  }, [patientInfo]);

  useEffect(() => {
    if (!diagnosisCodes) return;
    const defaultCode = diagnosisCodes.find((x) => x.isDefault);
    if (!defaultCode) return;
    setPatientInfo({
      type: ACTIONS.setDiagnosisCodeId,
      payload: defaultCode.id,
    });
  }, [diagnosisCodes, setPatientInfo]);

  useEffect(() => {
    if (!patientInfo.dateOfBirthday) return;
    setDateValue(dayjs(patientInfo.dateOfBirthday));
  }, [patientInfo.dateOfBirthday]);

  useEffect(() => {
    if (!patientInfo.dateOfBirthday) return;
    setError((prev) => ({ ...prev, dateOfBirthday: "" }));
  }, [patientInfo.dateOfBirthday]);

  useEffect(() => {
    const {
      firstName,
      lastName,
      dateOfBirthday,
      gender,
      email,
      parentPhoneNumber,
      insuranceId,
    } = patientInfo;

    const values = [
      firstName,
      lastName,
      dateOfBirthday,
      gender,
      email,
      parentPhoneNumber,
      insuranceId,
    ];
    setDisableSave(
      Object.values(error).some((x) => !!x) || values.some((x) => !x)
    );
  }, [error, patientInfo]);

  const onFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPatientInfo({
      type: ACTIONS.setFirstName,
      payload: capitalizer(value),
    });
    setError((prev) => ({ ...prev, firstName: "" }));
  };

  const onLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPatientInfo({
      type: ACTIONS.setLastName,
      payload: capitalizer(value),
    });
    setError((prev) => ({ ...prev, lastName: "" }));
  };

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPatientInfo({ type: ACTIONS.setEmail, payload: value });
    setError((prev) => ({ ...prev, email: "" }));
  };

  const onPhoneNumberChange = (value: Value) => {
    setPatientInfo({ type: ACTIONS.setParentPhoneNumber, payload: value });
    setError((prev) => ({ ...prev, parentPhoneNumber: "" }));
  };

  const onDateOfBirthChange = (value: Dayjs | null) => {
    if (!value) return;
    setDateValue(value);
    if (!dayjs(value).isValid()) return;
    const payload = dateNormalizer(value);
    setPatientInfo({ type: ACTIONS.setDateOfBirth, payload });
    setError((prev) => ({ ...prev, dateOfBirthday: "" }));
  };

  const onGenderChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setPatientInfo({ type: ACTIONS.setGender, payload: value });
    setError((prev) => ({ ...prev, gender: "" }));
  };

  const onAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPatientInfo({ type: ACTIONS.setAddress, payload: value });
  };

  const onInsuranceNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPatientInfo({ type: ACTIONS.setInsuranceNumber, payload: value });
    setError((prev) => ({ ...prev, insuranceNumber: "" }));
  };

  const onCityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPatientInfo({ type: ACTIONS.setCity, payload: value });
  };

  const onZipChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPatientInfo({ type: ACTIONS.setZip, payload: value });
  };

  const onStatusChange = (event: SelectChangeEvent<string>) => {
    const value = parseInt(event.target.value);
    setPatientInfo({ type: ACTIONS.setStatus, payload: value });
  };

  const onInsuranceChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    if (!value) return;
    setPatientInfo({ type: ACTIONS.setInsuranceId, payload: value });
    setError((prev) => ({ ...prev, insuranceId: "" }));
  };

  const onDiagnosisCodeIdChange = (event: SelectChangeEvent<string>) => {
    const payload = event.target.value;
    if (!payload) return;
    setPatientInfo({ type: ACTIONS.setDiagnosisCodeId, payload });
  };

  const onFirstNameBlurHandler = () => {
    if (!!patientInfo.firstName && validateName(patientInfo.firstName)) return;
    if (!patientInfo.firstName) {
      setError((prev) => ({ ...prev, firstName: "Required" }));
      return;
    }
    setError((prev) => ({ ...prev, firstName: "Invalid name" }));
  };

  const onLastNameBlurHandler = () => {
    if (!!patientInfo.lastName && validateName(patientInfo.lastName)) return;
    if (!patientInfo.lastName) {
      setError((prev) => ({ ...prev, lastName: "Required" }));
      return;
    }
    setError((prev) => ({ ...prev, lastName: "Invalid name" }));
  };

  const onEmailBlurHandler = () => {
    if (!!patientInfo.email) return;
    setError((prev) => ({ ...prev, email: "Required" }));
  };

  const onPhoneNumberBlurHandler = () => {
    const { parentPhoneNumber } = patientInfo;
    if (!parentPhoneNumber) {
      setError((prev) => ({ ...prev, parentPhoneNumber: "Required" }));
      return;
    }
    if (!isPossiblePhoneNumber(parentPhoneNumber)) {
      setError((prev) => ({ ...prev, phoneNumber: "Invalid phone number" }));
      return;
    }
  };

  const onDateOfBirthdayBlurHandler = () => {
    if (!!patientInfo.dateOfBirthday) return;
    setError((prev) => ({ ...prev, dateOfBirthday: "Required" }));
  };

  const onGenderBlurHandler = () => {
    if (!!patientInfo.gender) return;
    setError((prev) => ({ ...prev, gender: "Required" }));
  };

  const onInsuranceBlurHandler = () => {
    if (!!patientInfo.insuranceId) return;
    setError((prev) => ({ ...prev, insuranceId: "Required" }));
  };

  return !loading ? (
    <Container>
      <Text
        title={"Client Information"}
        size={"mediumBold"}
        className={"marginBottom16"}
      />
      <InputWrapper>
        <CustomInput
          label={"First Name"}
          value={patientInfo.firstName}
          setValue={onFirstNameChange}
          onBlur={onFirstNameBlurHandler}
          error={!!error.firstName}
          errorMessage={error.firstName}
        />
        <CustomInput
          label={"Last Name"}
          value={patientInfo.lastName}
          setValue={onLastNameChange}
          onBlur={onLastNameBlurHandler}
          error={!!error.lastName}
          errorMessage={error.lastName}
        />
      </InputWrapper>
      <InputWrapper>
        <Picker.CustomDate
          label={"Date of birth"}
          value={dateValue}
          onChange={onDateOfBirthChange}
          onBlur={onDateOfBirthdayBlurHandler}
          disableFuture={true}
          error={!!error.dateOfBirthday}
          errorMessage={error.dateOfBirthday}
        />
        <CustomSelect
          data={genderOptions}
          label={"Gender"}
          value={patientInfo.gender}
          setValue={onGenderChange}
          onBlur={onGenderBlurHandler}
          error={!!error.gender}
          errorMessage={error.gender}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomInput
          label={"Email"}
          value={patientInfo.email}
          setValue={onEmailChange}
          onBlur={onEmailBlurHandler}
          error={!!error.email}
          errorMessage={error.email}
        />
        <CustomPhoneInput
          label={"Parent's Phone"}
          value={patientInfo.parentPhoneNumber}
          setValue={onPhoneNumberChange}
          onBlur={onPhoneNumberBlurHandler}
          error={!!error.parentPhoneNumber}
          errorMessage={error.parentPhoneNumber}
        />
      </InputWrapper>
      <Text
        title={"Address"}
        size={"mediumBold"}
        className={"marginBottom16 marginTop16"}
      />
      <CustomInput
        label={"Address"}
        value={patientInfo.address}
        setValue={onAddressChange}
        className={"marginBottom16"}
      />
      <InputWrapper>
        <CustomInput
          label={"City"}
          value={patientInfo.city}
          setValue={onCityChange}
        />
        <CustomInput
          label={"ZIP"}
          value={patientInfo.zip}
          setValue={onZipChange}
        />
      </InputWrapper>
      <CustomSelect
        label={"Insurance"}
        className={"marginBottom16"}
        data={insurances}
        value={patientInfo.insuranceId}
        setValue={onInsuranceChange}
        onBlur={onInsuranceBlurHandler}
        error={!!error.insurance}
        errorMessage={error.insurance}
      />
      <CustomInput
        label={"Insurance number"}
        value={patientInfo.insuranceNumber}
        setValue={onInsuranceNumberChange}
        className={"marginBottom16"}
        error={!!error.insuranceNumber}
        errorMessage={error.insuranceNumber}
      />
      {!!patientId && (
        <CustomSelect
          label={"Status"}
          className={"marginBottom16"}
          data={statuses}
          value={patientInfo.status.toString()}
          setValue={onStatusChange}
        />
      )}
      <div
        style={{
          display: "flex",
          gap: "16px",
          flexWrap: "wrap",
          marginBottom: "16px",
        }}
      >
        {!!diagnosisCodes &&
          diagnosisCodes.map((code) => (
            <CustomRadio
              id={code.id}
              value={patientInfo.diagnosisCodeId}
              label={code.name}
              onChange={onDiagnosisCodeIdChange}
            />
          ))}
      </div>
      <CustomButton
        title={"Save"}
        onClick={savePatientHandler}
        disabled={disableSave}
        className={"marginBottom16"}
      />
    </Container>
  ) : (
    <Loader />
  );
};

export default PatientForm;
