import { Dispatch, ChangeEvent, useEffect, useReducer } from "react";
import { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  CustomInput,
  CustomSelect,
  MultipleSelect,
  Text,
} from "../../../../../shared/uiComponents";
import {
  ACTIONS,
  ActionProperties,
  ERRORS,
  initialTreatmentPlaningErrorState,
  treatmentPlaningErrorReducer,
} from "./treatmentPlanningReducer";
import { SessionBehaviorsInfo, SessionTargetsInfo, TimeInfo } from "../helpers";
import { useTreatmentPlanningOptions } from "../hook";
import { SaveTreatmentPlanningTypeProperties } from "../../SoapNoteTypesProperties/treatmentPlanningTypeProperties";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import { getLocations } from "../../../../../redux/State/clientSlice/soapNoteSlice";
import dayjs from "dayjs";
import { validateName } from "../../../../../shared/Helpers/functions";
import { toast } from "react-toastify";

const TreatmentPlanningData = ({
  data,
  setData,
  disabled = false,
}: {
  data: SaveTreatmentPlanningTypeProperties;
  setData: Dispatch<ActionProperties>;
  disabled?: boolean;
}) => {
  const today = new Date();
  const todayString = dayjs(today).format("MM/DD/YYYY");

  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const { participantsList, treatmentUpdatesList } =
    useTreatmentPlanningOptions();
  const [errors, setErrors] = useReducer(
    treatmentPlaningErrorReducer,
    initialTreatmentPlaningErrorState
  );

  const locationList = useSelector((state) => state.soapNote.locations);
  const { user } = useSelector((state) => state.session.sessionInfo);
  const errorList = useSelector((state) => state.soapNote.error.errors);

  useEffect(() => {
    if (!errorList) return;
    const list = Object.keys(errorList);
    list.forEach((id) => setErrors({ type: ERRORS[id], payload: true }));
  }, [errorList]);

  useEffect(() => {
    const clientId = params.get("clientId");
    if (!!locationList || !clientId) return;
    dispatch(getLocations(clientId));
  }, [dispatch, locationList, params]);

  const participantsHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ type: ACTIONS.setParticipants, payload: value });
    if (!errors.ParticipantResponses) return;
    setErrors({ payload: false, type: ERRORS.ParticipantResponses });
  };

  const locationHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setLocationId });
    if (!errors.LocationId) return;
    setErrors({ payload: false, type: ERRORS.LocationId });
  };

  const masteredGoalsAndObjectivesHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setMasteredGoalsAndObjectives, payload: value });
    if (!errors.MasteredGoalsAndObjectives) return;
    setErrors({ payload: false, type: ERRORS.MasteredGoalsAndObjectives });
  };

  const newGoalsAndObjectivesHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setNewGoalsAndObjectives, payload: value });
    if (!errors.NewGoalsAndObjectives) return;
    setErrors({ payload: false, type: ERRORS.NewGoalsAndObjectives });
  };

  const treatmentUpdateResponsesHandler = (
    event: SelectChangeEvent<string[]>
  ) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ type: ACTIONS.setTreatmentUpdateResponses, payload: value });
    if (!errors.TreatmentUpdateResponses) return;
    setErrors({ payload: false, type: ERRORS.TreatmentUpdateResponses });
  };

  const analysisOfGoalsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setAnalysisOfGoals, payload: value });
    if (!errors.AnalysisOfGoals) return;
    setErrors({ payload: false, type: ERRORS.AnalysisOfGoals });
  };

  const goalUpdatesHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setGoalUpdates, payload: value });
    if (!errors.GoalUpdates) return;
    setErrors({ payload: false, type: ERRORS.GoalUpdates });
  };

  const coordinationAndMaterialsPreppedHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({
      type: ACTIONS.setCoordinationAndMaterialsPrepped,
      payload: value,
    });
    if (!errors.CoordinationAndMaterialsPrepped) return;
    setErrors({ payload: false, type: ERRORS.CoordinationAndMaterialsPrepped });
  };

  const descriptionOfSessionHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({
      type: ACTIONS.setDescriptionOfSession,
      payload: value,
    });
    if (!errors.DescriptionOfSession) return;
    setErrors({ payload: false, type: ERRORS.DescriptionOfSession });
  };

  const signatureHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({
      type: ACTIONS.setSignature,
      payload: value,
    });
    if (!errors.Signature) return;
    setErrors({ payload: false, type: ERRORS.Signature });
  };

  const validateSignatureHandler = () => {
    if (validateName(data.signature)) return;
    toast("Signature must contain only letters.");
    setErrors({ payload: true, type: ERRORS.Signature });
  };

  return (
    <div>
      <TimeInfo disabled={disabled} />
      <CustomSelect
        label="Location:"
        data={locationList}
        value={!!data.locationId ? data.locationId : ""}
        setValue={locationHandler}
        className={"marginBottom16"}
        error={errors.LocationId}
        disabled={disabled}
      />
      <MultipleSelect
        label="Participants:"
        data={participantsList}
        initialValue={data.participantResponses}
        setValue={participantsHandler}
        error={errors.ParticipantResponses}
        className="marginBottom16"
        disabled={disabled}
      />
      <CustomInput
        label="Mastered goals and objectives:"
        value={data.masteredGoalsAndObjectives}
        setValue={masteredGoalsAndObjectivesHandler}
        error={errors.MasteredGoalsAndObjectives}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        className="marginBottom16"
        disabled={disabled}
      />
      <CustomInput
        label="New goals and objectives:"
        value={data.newGoalsAndObjectives}
        setValue={newGoalsAndObjectivesHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        error={errors.NewGoalsAndObjectives}
        className="marginBottom16"
        disabled={disabled}
      />
      <MultipleSelect
        label="Updates to Treatment:"
        data={treatmentUpdatesList}
        initialValue={data.treatmentUpdateResponses}
        setValue={treatmentUpdateResponsesHandler}
        error={errors.TreatmentUpdateResponses}
        className="marginBottom16"
        disabled={disabled}
      />
      <CustomInput
        label="Analysis of goals reviewed:"
        value={data.analysisOfGoals}
        setValue={analysisOfGoalsHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        error={errors.AnalysisOfGoals}
        className="marginBottom16"
        disabled={disabled}
      />
      <CustomInput
        label="Updates to goals:"
        value={data.goalUpdates}
        setValue={goalUpdatesHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        className="marginBottom16"
        error={errors.GoalUpdates}
        disabled={disabled}
      />
      <CustomInput
        label="Coordination and materials prepped:"
        value={data.coordinationAndMaterialsPrepped}
        setValue={coordinationAndMaterialsPreppedHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        error={errors.CoordinationAndMaterialsPrepped}
        className="marginBottom16"
        disabled={disabled}
      />
      <Text
        title="Session Details"
        size="smallBold"
        className="marginBottom16 title"
      />
      <SessionTargetsInfo />
      <SessionBehaviorsInfo />
      <CustomInput
        label="Description of Summary: "
        value={data.descriptionOfSession}
        setValue={descriptionOfSessionHandler}
        error={errors.DescriptionOfSession}
        className="marginBottom8"
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        disabled={disabled}
      />
      <div style={{ width: "250px" }}>
        <CustomInput
          label="Printed Name:"
          value={`${user.fullName}`.toUpperCase()}
          setValue={() => {}}
          disabled={true}
          className="marginBottom8 signature"
        />
        <CustomInput
          label="Signature:"
          value={data.signature}
          setValue={signatureHandler}
          error={errors.Signature}
          className="marginBottom8 signature"
          disabled={disabled}
          onBlur={validateSignatureHandler}
        />
      </div>
      <div>
        <Text title="Date of signature: " size="smallBold" />
        <Text title={todayString} />
      </div>
    </div>
  );
};

export default TreatmentPlanningData;
