import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "@mui/material";
import {
  EditOutlined as Edit,
  InfoOutlined as Info,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { toast } from "react-toastify";

import Delete from "../Delete";
import { CustomAccordion, CustomAlert, CustomButton, Text } from "../../shared/uiComponents";
import { Loader, InfoRow as Row } from "../../pages/AdminPanel/StyledComponents";
import { errorNormalizer } from "../../shared/Helpers/functions";
import { UserStatusBadge } from "../../shared/uiComponents/StatusBadge";
import { useCheckPermission } from "../../pages/AdminPanel/Permissions/helpers";
import { PERMISSIONS } from "../../App/constants";

import { DispatchProperties, useSelector } from "../../redux/store";
import {
  getUserAccountDetails,
  resendVerificationEmail,
} from "../../redux/State/identitySlice/userSlice";
import { UserProperties } from "../../redux/API/userAPIProperties";
import { deleteUser, getUserInsurances } from "../../redux/State/userSlice";
import { dismissPatient, getUserPatients } from "../../redux/State/clientSlice/userClientSlice";
import { DismissClientProperties } from "../../redux/API/ClientAPIHelpers/userClientProperties";
import { InfoClientProperties } from "../../redux/API/ClientAPIHelpers/clientProperties";
import { UserRegistrationInfoProperties } from "../../redux/API/identityAPIProperties";

export type PanelTypes = "admin" | "bcba" | "rbt";
export enum AdminTypes {
  "administrator",
  "bcba",
  "rbt",
}

const Action = ({
  item,
  fetchUsers,
}: {
  item: UserProperties;
  fetchUsers: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const EDIT = useCheckPermission(PERMISSIONS.USER.EDIT);
  const DELETE = useCheckPermission(PERMISSIONS.USER.DELETE);
  const { pathname } = useLocation();

  const [infoOpen, setInfoOpen] = useState<boolean>(false);

  const showUserInfo = () => {
    setInfoOpen(true);
  };

  const onDeleteClick = (id: string) => {
    dispatch(deleteUser(id))
      .then(unwrapResult)
      .then(() => {
        toast(`Deleted`);
        fetchUsers();
      })
      .catch(errorNormalizer);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {EDIT.permissionGranted && (
        <>
          <Link to={`${pathname}/${item.id}`} className={"marginRight16"}>
            <Tooltip title="Edit">
              <Edit fontSize="small" />
            </Tooltip>
          </Link>
        </>
      )}
      <div
        onClick={showUserInfo}
        className={"marginRight16"}
        style={{ cursor: "pointer" }}
      >
        <Tooltip title="More Information">
          <Info />
        </Tooltip>
      </div>
      {DELETE.permissionGranted && (
        <Delete deleteHandler={() => onDeleteClick(item.id)} />
      )}
      <CustomAlert
        open={infoOpen}
        onClose={() => setInfoOpen(false)}
        title={"Account Details"}
        Content={({ onClose }) => <UserAccountInfo item={item} onClose={onClose} />}
      />
    </div>
  );
};

const UserAccountInfo = ({ onClose, item }: { onClose: () => void, item: UserProperties }) => {
  const dispatch = useDispatch<DispatchProperties>();

  const accountInfo = useSelector((state) => state.identityUser.accountInfo);

  const loading = useSelector(
    (state) => state.identityUser.loadingAccountInfo
  );

  const resendVerificationHandler = () => {
    if (!item.id) return;
    dispatch(resendVerificationEmail(item.id))
      .then(unwrapResult)
      .then(() => toast("Success"))
      .catch(errorNormalizer)
      .finally(() => onClose());
  };

  useEffect(() => {
    if (!item.id) return;
    dispatch(getUserAccountDetails(item.id));
  }, [item.id, dispatch]);

  return loading ? <Loader /> : (
    <>
      <Row>
        <Text title={"Email confirmed:"} size={"smallBold"} className="marginLeft8" />
        <div className="paddingLeft16">
          <UserStatusBadge emailConfirmed={accountInfo.emailConfirmed} />
        </div>
      </Row>
      <UserInfo accountInfo={accountInfo} userInfo={item} />
      <InsuranceList id={item.id} />
      <PatientsList item={item} />
      <CustomButton
        title="Resend Verification"
        onClick={resendVerificationHandler}
        disabled={accountInfo.emailConfirmed}
      />
    </>
  );
}

const UserInfo = ({ accountInfo, userInfo }: { accountInfo: UserRegistrationInfoProperties, userInfo: UserProperties }) => {

  const {
    email,
    previousEmail,
    lastConfirmationEmailSendDate,
    lastEmailChangedDate,
    lastPasswordChangedDate,
  } = accountInfo;

  const {
    fullName,
    phoneNumber,
    address,
    authorizationType,
    createdBy,
    role,
  } = userInfo;

  return (
    <CustomAccordion
      headerStyle={{ padding: 0 }}
      HeaderContent={() => <Row>
        <Text size={"smallBold"} title={`Name:`} />
        <Text title={fullName} />
      </Row>}
      Content={() => (
        <>
          {!!phoneNumber && <Row>
            <Text title={"Phone Number: "} size={"smallBold"} />
            <Text title={phoneNumber} />
          </Row>}
          <Row>
            <Text title={"Email: "} size={"smallBold"} />
            <Text title={email} />
          </Row>
          {!!previousEmail && <Row>
            <Text title={"Previous Email: "} size={"smallBold"} />
            <Text title={previousEmail} />
          </Row>}
          {!!lastEmailChangedDate && <Row>
            <Text title={"Last Email Change Date: "} size={"smallBold"} />
            <Text title={dayjs(lastEmailChangedDate).format(
              "MM/DD/YYYY hh:mm A"
            )} />
          </Row>}
          <Row>
            <Text title={"Address: "} size={"smallBold"} />
            <Text title={address} />
          </Row>
          {authorizationType?.id === 1 && (
            <>
              <Row>
                <Text title={"Authorization Type: "} size={"smallBold"} />
                <Text title={authorizationType?.name ?? "-"} />
              </Row>
              <Row>
                <Text title={"Role: "} size={"smallBold"} />
                <Text title={role.name} />
              </Row>
              <Row>
                <Text title={"Section: "} size={"smallBold"} />
                <Text title={role.section.name} />
              </Row>
            </>
          )}
          <Row>
            <Text title={"Created by: "} size={"smallBold"} />
            <Text title={createdBy.fullName} />
          </Row>
          {lastConfirmationEmailSendDate && (
            <Row>
              <Text title={"Recent Resend Date:"} size={"smallBold"} />
              <Text
                title={dayjs(lastConfirmationEmailSendDate).format(
                  "MM/DD/YYYY hh:mm A"
                )}
              />
            </Row>
          )}
          {lastPasswordChangedDate && (
            <Row>
              <Text title={"Last Password Change Date:"} size={"smallBold"} />
              <Text title={dayjs(lastPasswordChangedDate).format("MM/DD/YYYY")} />
            </Row>
          )}
        </>
      )}
    />
  )
}

const InsuranceList = ({ id }: { id: string }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const userInsurances = useSelector(state => state.user.userInsurances);

  useEffect(() => {
    if (!id) return;
    dispatch(getUserInsurances(id))
  }, [id, dispatch]);

  return !!userInsurances.length ? <CustomAccordion
    headerStyle={{ padding: 0 }}
    HeaderContent={() => (
      <Row>
        <Text title={"Insurances: "} size={"smallBold"} />
        <Text title={`${userInsurances.length}`} />
      </Row>
    )}
    Content={() => <>{userInsurances.map((insurance, index) => (
      <Text title={`${index + 1}. ${insurance.name}`} className={"marginRight8"} />
    ))}</>}
  /> : null
}

const PatientsList = ({ item }: { item: UserProperties }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { id } = item;

  const [patientsPageSize, setPatientsPageSize] = useState<number>(8);

  const patients = useSelector((state) => state.userClient.userPatients);
  const loading = useSelector(
    (state) => state.userClient.loadingGetUserPatients
  );

  useEffect(() => {
    if (!id) return;
    dispatch(
      getUserPatients({ userId: id, page: '1', pageSize: patientsPageSize.toString() })
    );
  }, [id, patientsPageSize, dispatch]);

  return <CustomAccordion
    headerStyle={{ padding: 0 }}
    HeaderContent={() => (
      <Row>
        <Text title={"Clients Assigned: "} size={"smallBold"} />
        <Text title={`${patients.numberOfItems}`} />
      </Row>
    )}
    Content={() => (
      <>
        {!!patients.query?.length && !loading ? (
          <>
            {patients.query.map((patient, index) => <ListItem index={index} patient={patient} userId={id} />)}
            {patients.hasNextPage && (
              <div style={{ justifyContent: "center", display: "flex" }}>
                <Text
                  title="Load More"
                  size="smallBold"
                  onClick={() => setPatientsPageSize((prev) => prev + 8)}
                />
              </div>
            )}
          </>
        ) : (
          <Text title={"None"} />
        )}
        {loading && <Loader />}
      </>
    )}
  />
}

const ListItem = ({ patient, index, userId }: { patient: InfoClientProperties, index: number, userId: string }) => {
  const dispatch = useDispatch<DispatchProperties>();

  const fetchUserPatients = useCallback(() => {
    if (!userId) return;
    dispatch(
      getUserPatients({ userId, page: '1', pageSize: '8' })
    );
  }, [userId, dispatch]);

  const dismissHandler = ({ clientId, userId }: DismissClientProperties) => {
    dispatch(dismissPatient({ clientId, userId }))
      .then(unwrapResult)
      .then(() => {
        toast("Removed");
        fetchUserPatients();
      })
      .catch(errorNormalizer);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      key={index}
    >
      <Text
        title={`${index + 1}. ${patient.fullName}`}
        className={"marginRight8"}
      />
      {!!patient.id && !!userId && (
        <Delete
          deleteHandler={() =>
            dismissHandler({
              clientId: patient.id,
              userId,
            })
          }
        />
      )}
    </div>
  )
}

export default Action;
