import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DayProperties } from "../../../pages/BCBAPanel/Calendar/Helpers/interfaces";
import { findDateRange } from "../../../pages/BCBAPanel/Calendar/Helpers/functions";
import API from "../../API/Main/API";
import {
    CopyEventsProperties,
    CreateEventProperties,
    DeleteEventsProperties,
    DeleteUpcomingEventsProperties,
    DeleteUserEventProperties,
    EventDataProperties,
    GetEventsProperties,
} from "../../API/ClientAPIHelpers/calendarProperties";
import { ErrorProperties } from "../../API/identityAPIProperties";

const today = new Date();
const day = today.getDate();
const month = today.getMonth();
const year = today.getFullYear();
const weekDayOfFirstDayOfMonth = new Date(year, month, 1).getDay();
const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
const lastDayOfPreviousMonth = new Date(year, month, 0).getDate();

enum CalendarView {
    month,
    week,
}

interface CalendarStateProperties {
    loading: boolean;
    events: Array<EventDataProperties> | null;
    event: EventDataProperties;
    month: number,
    year: number,
    lastDayOfMonth: number,
    lastDayOfPreviousMonth: number,
    weekDay: number,
    calendarView: CalendarView,
    displayedCalendarPageDays: Array<DayProperties>,
    popups: {
        add: boolean,
        info: boolean,
        copy: boolean,
        delete: boolean,
    },
    selectedDay: DayProperties,
    infoEventData: EventDataProperties | null,
    error: ErrorProperties;
}

const initialEventState: EventDataProperties = {
    id: '',
    isCompleted: false,
    date: '',
    startTime: '',
    endTime: '',
    title: '',
    type: '',
    sessionType: {
        id: 0,
        name: '',
        type: 1,
    },
    user: {
        id: '',
        fullName: "",
        firstName: '',
        lastName: '',
        email: '',
        role: {
            id: "",
            name: "",
            section: {
                id: 0,
                name: ""
            }
        },
    },
    client: {
        id: '',
        dateOfBirthday: '',
        email: '',
        fullName: '',
        gender: '',
        defaultBcba: {
            id: '',
            fullName: "",
            firstName: '',
            lastName: '',
            email: '',
            role: {
                id: "",
                name: "",
                section: {
                    id: 0,
                    name: ""
                }
            },
        }
    },
    sessionId: '',
    reportStatus: null,
    reportId: null,
}

const InitialCalendarState: CalendarStateProperties = {
    loading: false,
    events: null,
    event: initialEventState,
    month,
    year,
    lastDayOfMonth,
    lastDayOfPreviousMonth,
    weekDay: weekDayOfFirstDayOfMonth,
    calendarView: 0,
    displayedCalendarPageDays: [],
    popups: {
        add: false,
        info: false,
        copy: false,
        delete: false,
    },
    selectedDay: {
        day,
        month,
        year,
        status: 1,
    },
    infoEventData: null,
    error: {
        status: 0,
        title: ''
    },
}

export const createEvent = createAsyncThunk(
    '/calendar/create-event',
    async (data: CreateEventProperties, thunkAPI) => {
        const response = await API.ClientAPI.Calendar.createEvent(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as EventDataProperties;
    }
)

export const copyEvents = createAsyncThunk(
    '/calendar/copy-events',
    async (data: CopyEventsProperties, thunkAPI) => {
        const response = await API.ClientAPI.Calendar.copyEvents(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getEvents = createAsyncThunk(
    '/calendar/get-events',
    async ({ days, clientId, userId, includeRbtEvents }: GetEventsProperties, thunkAPI) => {
        const range = findDateRange(days);
        let response: any = null;
        if (!clientId && !userId) {
            response = await API.ClientAPI.Calendar.getAllEvents({ ...range, includeRbtEvents });
        }
        if (!clientId && !!userId) {
            response = await API.ClientAPI.Calendar.getUserEvents({ ...range, userId });
        }
        if (!!clientId && !userId) {
            response = await API.ClientAPI.Calendar.getClientEvents({ ...range, clientId });
        }
        if (!!clientId && !!userId) {
            const clientEvents = await API.ClientAPI.Calendar.getClientEvents({ ...range, clientId });
            if (!response.error) {
                response = clientEvents.filter((x: EventDataProperties) => x.user.id === userId);
            }
        }

        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }

        return response as Array<EventDataProperties>
    }
)

export const getAllEventsForOneDay = createAsyncThunk(
    '/calendar/all-events-for-one-day',
    async ({ includeRbtEvents, date }: { date: string, includeRbtEvents?: boolean }, thunkAPI) => {
        const response = await API.ClientAPI.Calendar.getAllEvents({ startDate: date, endDate: date, includeRbtEvents });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<EventDataProperties>;
    }
)

export const deleteEvent = createAsyncThunk(
    '/calendar/delete-event',
    async (eventId: string, thunkAPI) => {
        const response = await API.ClientAPI.Calendar.deleteEvent(eventId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<EventDataProperties>;
    }
)

export const deleteUpcomingEvent = createAsyncThunk(
    '/calendar/delete-upcoming-event',
    async (data: DeleteUpcomingEventsProperties, thunkAPI) => {
        const response = await API.ClientAPI.Calendar.deleteUpcomingEvents(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const deleteEvents = createAsyncThunk(
    '/calendar/delete-events',
    async (data: DeleteEventsProperties, thunkAPI) => {
        const response = await API.ClientAPI.Calendar.deleteEvents(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const deleteUserEvent = createAsyncThunk(
    '/calendar/delete-user-event',
    async (data: DeleteUserEventProperties, thunkAPI) => {
        const response = await API.ClientAPI.Calendar.deleteUserEvent(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

const CalendarSlice = createSlice({
    name: "calendar",
    initialState: InitialCalendarState,
    reducers: {
        clearEvents(state) {
            state.events = InitialCalendarState.events;
        },
        setDays(state, action) {
            state.displayedCalendarPageDays = action.payload;
        },
        setMonth(state, action) {
            state.month = action.payload;
        },
        setYear(state, action) {
            state.year = action.payload;
        },
        setLastDayOfMonth(state, action) {
            state.lastDayOfMonth = action.payload;
        },
        setLastDayOfPreviousMonth(state, action) {
            state.lastDayOfPreviousMonth = action.payload;
        },
        setWeekDay(state, action) {
            state.weekDay = action.payload;
        },
        setCalendarView(state, action: { type: string, payload: CalendarView }) {
            state.calendarView = action.payload;
        },
        setAddEvent(state, action) {
            state.popups.add = action.payload;
            state.popups.info = InitialCalendarState.popups.info;
            state.popups.copy = InitialCalendarState.popups.copy;
            state.popups.delete = InitialCalendarState.popups.delete;
        },
        setInfoEvent(state, action) {
            state.popups.add = InitialCalendarState.popups.add;
            state.popups.info = action.payload;
            state.popups.copy = InitialCalendarState.popups.copy;
            state.popups.delete = InitialCalendarState.popups.delete;
        },
        setCopyEvent(state, action) {
            state.popups.add = InitialCalendarState.popups.add;
            state.popups.info = InitialCalendarState.popups.info;
            state.popups.copy = action.payload;
            state.popups.delete = InitialCalendarState.popups.delete;
        },
        setDeleteEvent(state, action) {
            state.popups.add = InitialCalendarState.popups.add;
            state.popups.info = InitialCalendarState.popups.info;
            state.popups.copy = InitialCalendarState.popups.copy;
            state.popups.delete = action.payload;
        },
        setInfoEventData(state, action) {
            state.infoEventData = action.payload;
        },
        setSelectedDay(state, action: { type: string, payload: DayProperties }) {
            state.selectedDay = action.payload;
        },
    },
    extraReducers: (builder) => {
        //deleteUserEvent
        builder.addCase(deleteUserEvent.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(deleteUserEvent.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(deleteUserEvent.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error as ErrorProperties;
        })
        //get events
        builder.addCase(getEvents.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getEvents.fulfilled, (state, action) => {
            state.loading = false;
            state.events = action.payload;
        })
        builder.addCase(getEvents.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error as ErrorProperties;
        })
        //create event
        builder.addCase(createEvent.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(createEvent.fulfilled, (state, action) => {
            state.loading = false;
            state.event = action.payload;
        })
        builder.addCase(createEvent.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error as ErrorProperties;
        })

        //copy events
        builder.addCase(copyEvents.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(copyEvents.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(copyEvents.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error as ErrorProperties;
        })

        //delete event
        builder.addCase(deleteEvent.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(deleteEvent.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(deleteEvent.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error as ErrorProperties;
        })

        //delete events
        builder.addCase(deleteEvents.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(deleteEvents.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(deleteEvents.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error as ErrorProperties;
        })

        //delete upcoming events
        builder.addCase(deleteUpcomingEvent.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(deleteUpcomingEvent.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(deleteUpcomingEvent.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error as ErrorProperties;
        })

    }
})

export const {
    clearEvents,
    setLastDayOfMonth,
    setLastDayOfPreviousMonth,
    setMonth,
    setYear,
    setWeekDay,
    setCalendarView,
    setDays,
    setAddEvent,
    setCopyEvent,
    setDeleteEvent,
    setInfoEvent,
    setInfoEventData,
    setSelectedDay,
} = CalendarSlice.actions;
export default CalendarSlice.reducer;